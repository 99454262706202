//
// Notes
//


// Variables
$timeline-5-media-size: 35px;

.timeline.timeline-5 {
    .timeline-items {
        margin: 0;
        padding: 0;

        .timeline-item {
            margin-left: 0;
            padding: 0;
            position: relative;

            .timeline-media {
                position: absolute;
                top: 0;
                border-radius: 100%;
                width: $timeline-5-media-size;
                height: $timeline-5-media-size;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                line-height: 0;

                i {
                    font-size: 1.4rem;
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                }
            }

			.timeline-info {
				padding: 9px 0 0 44px;

				&::before{
					content: '';
					position: absolute;
					width: 4px;
					height: calc(100% - 32px);
					background-color: #DEEDFF;
					border-radius: 6px;
					top: 40px;
					left: 16px;
				}
			}

            &:last-child {
                border-left-color: transparent;
                padding-bottom: 0;
            }
        }
    }
}
