//
// Bar Chart
//


.bar-chart {
	display: flex;
	width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: stretch;
	transition: all 0.3s ease;

    .bar-chart-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bar-chart-value {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
    		border-radius: 6px;
    		transition: all .6s ease;
    		width: 12px;

            [class*="bar-chart-value-"] {
                display: flex;
                width: 12px;
                border-radius: 6px;
            }

            @for $i from 1 through 100 {
        		.bar-chart-value-#{$i} {
        			height: unquote($i + '%');
        		}
        	}
        }

        .bar-chart-label {
            margin-top: 1rem;
            font-size: 0.9rem;
            color: $text-muted;
            font-weight: 500;
        }
    }

    // Color options
    @each $name, $color in $theme-colors {
        &.bar-chart-#{$name} {
            .bar-chart-value {
                background-color: theme-hover-color($name);
            }

            [class*="bar-chart-value-"] {
                background-color: $color;
            }
        }
    }
}
