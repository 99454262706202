.historys-content {
    ul {
        list-style-type: none !important;
    }

    .vl {
        padding: 0 8px;
    }

    .vl:before {
        border-left: 2px solid #b8b8b8;
        content: '\00a0';
        display: inline-block;
        width: 0;
    }

    .xsearch-items {
        padding-left: 0px;
    }

    .search-item-img {
        float: left;
        position: relative;
    }

    .search-item-img img,
    .search-item-img .img-holder {
        height: 70px;
        width: 70px;
        display: block;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
        border: 4px solid white;
    }

    .search-item-img .img-holder {
        border: 1px solid #e3e3e3;
        line-height: 20px;
        background: #f7f7f7;
        border-color: rgba(207, 215, 223, 0.25);
    }

    .search-item-img .img-holder i {
        display: inline-block;
        padding: 28px 20px;
        font-size: 28px;
        opacity: 0.5;
    }

    .search-item-content {
        margin-left: 100px;
        padding-bottom: 35px;
        margin-bottom: 20px;
        /*border-bottom: 1px solid rgb(231, 231, 231);*/
    }

    .search-item-content.no-excerpt h3 {
        margin-top: 8px;
    }

    .search-item-content .search-item-meta {
        display: block;
        margin-bottom: 10px;
    }

    .search-item-meta-down a,
    .search-item-meta a {
        font-size: 11px;
        text-transform: uppercase;
    }

    .xsearch-items a:hover {
        border-bottom-width: 1px;
        border-bottom-style: dotted;
    }

    .search-item-content .time {
        color: #999;
        font-size: 11px;
        text-transform: uppercase;
    }

    .search-item-content time,
    .search-item-content span {
        color: #999999;
    }

    .xsearch-items h3.search-item-caption {
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 20px;
    }

    .xsearch-items .search-item-meta-down {
        margin-top: 5px;
        color: #999;
        font-size: 12px;
    }

    .xsearch-items .star-vote li {
        padding: 0;
        font-size: 14px;
    }

    .xsearch-result-count {
        color: #999;
        margin-bottom: 30px;
    }

    ul.xsearch-items-2 {
        padding-left: 0;
        margin-left: 0;
    }

    .xsearch-items-2 li {
        list-style: none;
    }

    .xsearch-info-meta:before,
    .xsearch-info-meta:after {
        content: "";
        display: table;
    }

    .xsearch-info-meta:after {
        clear: both;
    }

    .xsearch-info-meta {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-bottom: 5px;
        font-size: 12px;
        opacity: 0.7;
    }

    .xsearch-info-meta-item {
        float: left;
        margin-right: 10px;
    }

    .xsearch-item-title h3,
    .xsearch-item-title h4 {
        margin-bottom: 5px;
    }

    .xsearch-desc {
        margin-bottom: 2px;
    }

    .search-item-icon {
        padding-right: 3px;
    }

    nav.xsearch-navbar {
        padding-left: 0;
    }

    nav.xsearch-navbar.navbar-light .navbar-nav .active > .nav-link {
        border-bottom-width: 2px;
        border-bottom-style: solid;
        padding-bottom: 12px;
    }

    .xsearch-item .xsearch-item-title strong {
        font-weight: 600;
    }

    ul.xsearch-items-2 .xsearch-item {
        margin-bottom: 40px;
    }

    .xsearch-item .xsearch-desc strong {
        color: #111;
    }

    .search-result-wrap .search-result-item {
        padding-bottom: 25px;
        padding-top: 25px;
        border-bottom: 1px solid rgba(207, 215, 223, 0.25);
    }

    .search-result-wrap .search-result-item .title h4,
    .search-result-wrap .search-result-item .title h3 {
        margin-bottom: 5px;
    }

    .search-result-item-meta {
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 25px;
    }

    .search-result-item-meta li {
        color: #999;
        margin-right: 5px;
    }

    .search-result-item-meta li i {
        margin-right: 4px;
    }

    .search-result-item-meta li a {
        /*border-bottom:1px dotted rgba(207,215,223,.25);
        padding-bottom:2px;*/
        color: #1a0dab;
    }

    .search-result-item-link,
    .search-result-item-excerpt,
    .search-result-item-meta {
        margin-left: 25px;
    }

    .search-result-item-excerpt strong {
        color: #444;
        font-weight: 600;
    }

    .search-result-item-excerpt {
        margin-bottom: 5px;
    }

    .search-result-item-meta li:first-child {
        margin-left: 0;
        padding-left: 0;
    }
}
