//
// Sticky Toolbar
//

.sticky-toolbar {
    width: 46px;
    position: fixed;
    top: 30%;
    right: 0;
    list-style: none;
    margin: 0;
    z-index: 50;
    background: #ffffff;
    box-shadow: $dropdown-box-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include border-top-left-radius($border-radius);
    @include border-bottom-left-radius($border-radius);
}
