.login-1 {
    .img-backdrop::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        // background-color: rgba(0, 0, 0, 0.3);
        // background: linear-gradient(270deg,#f10028 0,#f26721) !important;
    }
}
